import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import PrimaryBoxInfo from 'components/Advanced_User/All_ioBroker_Tutorials/PrimaryBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "ioBroker v7 as MQTT Broker for your WQHD INSTAR Camera",
  "path": "/Frequently_Asked_Question/ioBroker_as_Broker_INSTAR_WQHD_MQTT/",
  "dateChanged": "2023-03-16",
  "author": "Mike Polinowski",
  "excerpt": "I installed my MQTT camera according to your guide in ioBroker. But now I do use the MQTT Adapter as my primary MQTT Broker - not as a MQTT Client. And I am unable to update Command Topics through the ioBroker object tree.",
  "image": "../FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "WQHD & UHD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='ioBroker v7 as MQTT Broker for your WQHD INSTAR Camera' dateChanged='2023-03-16' author='Mike Polinowski' tag='INSTAR IP Camera' description='I installed my MQTT camera according to your guide in ioBroker. But now I do use the MQTT Adapter as my primary MQTT Broker - not as a MQTT Client. And I am unable to update Command Topics through the ioBroker object tree.' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/ioBroker_as_Broker_INSTAR_WQHD_MQTT/' locationFR='/fr/Frequently_Asked_Question/ioBroker_as_Broker_INSTAR_WQHD_MQTT/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <PrimaryBoxInfo mdxType="PrimaryBoxInfo" />
    <EuiSpacer mdxType="EuiSpacer" />
    
    <h1 {...{
      "id": "iobroker-v7-as-mqtt-broker-for-your-wqhd-instar-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#iobroker-v7-as-mqtt-broker-for-your-wqhd-instar-camera",
        "aria-label": "iobroker v7 as mqtt broker for your wqhd instar camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ioBroker v7 as MQTT Broker for your WQHD INSTAR Camera`}</h1>
    <p><strong parentName="p">{`Q`}</strong>{`: I installed my `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/"
      }}>{`MQTT`}</a>{` camera `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/ioBroker/"
      }}>{`according to your guide in ioBroker`}</a>{`. But now I do use the MQTT Adapter as my primary MQTT Broker - not as a MQTT Client. And I am unable to update Command Topics through the ioBroker object tree.`}</p>
    <p><strong parentName="p">{`A`}</strong>{`: Start by installing the MQTT `}<strong parentName="p">{`Server/broker`}</strong>{` Adapter:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c5133885582bef7ce077d735f91206a8/ae21e/ioBroker_as_MQTT_Broker_00.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "23.043478260869563%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABB0lEQVQY0y2MvU7DMBRGr2Onju3rOGlSlKRV/xOpSu1WSLQVgiUvwEM0lWBiYE8jRsRDI6DLp3OG8wEAxCt7ev96eO2Pb5/u3O0u/W3bqz1fXdu5tndtb9vrH3Tu3LlL33x8Q9W8xCsbRgkmORGGCs0CKbRJ87FJR4gowtiMMhUNtTGBEDKMpcJsUqzLBcwPzSAt6s1hvm8gWd65x7R008rWW7ss1/Z0zFabqnaTyXh7v5/Vu2ll12U1X8z2T8+gBAcApVSap8aEQiFlg2ESBxgQQsBjGrXUEgDAo9TnURwSRgCA+Bx4cIuLceH7PvU8zjlqFFIAwL+GkWaMEQKMUtQo8ffLZ/QHSDMk/2bFHSUAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c5133885582bef7ce077d735f91206a8/e4706/ioBroker_as_MQTT_Broker_00.avif 230w", "/en/static/c5133885582bef7ce077d735f91206a8/d1af7/ioBroker_as_MQTT_Broker_00.avif 460w", "/en/static/c5133885582bef7ce077d735f91206a8/7f308/ioBroker_as_MQTT_Broker_00.avif 920w", "/en/static/c5133885582bef7ce077d735f91206a8/e1c99/ioBroker_as_MQTT_Broker_00.avif 1380w", "/en/static/c5133885582bef7ce077d735f91206a8/32706/ioBroker_as_MQTT_Broker_00.avif 1537w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c5133885582bef7ce077d735f91206a8/a0b58/ioBroker_as_MQTT_Broker_00.webp 230w", "/en/static/c5133885582bef7ce077d735f91206a8/bc10c/ioBroker_as_MQTT_Broker_00.webp 460w", "/en/static/c5133885582bef7ce077d735f91206a8/966d8/ioBroker_as_MQTT_Broker_00.webp 920w", "/en/static/c5133885582bef7ce077d735f91206a8/445df/ioBroker_as_MQTT_Broker_00.webp 1380w", "/en/static/c5133885582bef7ce077d735f91206a8/c3c33/ioBroker_as_MQTT_Broker_00.webp 1537w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c5133885582bef7ce077d735f91206a8/81c8e/ioBroker_as_MQTT_Broker_00.png 230w", "/en/static/c5133885582bef7ce077d735f91206a8/08a84/ioBroker_as_MQTT_Broker_00.png 460w", "/en/static/c5133885582bef7ce077d735f91206a8/c0255/ioBroker_as_MQTT_Broker_00.png 920w", "/en/static/c5133885582bef7ce077d735f91206a8/b1001/ioBroker_as_MQTT_Broker_00.png 1380w", "/en/static/c5133885582bef7ce077d735f91206a8/ae21e/ioBroker_as_MQTT_Broker_00.png 1537w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c5133885582bef7ce077d735f91206a8/c0255/ioBroker_as_MQTT_Broker_00.png",
              "alt": "INSTAR MQTT for ioBroker",
              "title": "INSTAR MQTT for ioBroker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Make sure that the prefix field is left empty, you check the option to publish topics if their value changed and set the `}<strong parentName="p">{`Quality-of-Service`}</strong>{` to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`1 - At least once`}</code>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a054e29a7c5a55f86c9080e2a5f433c3/c5394/ioBroker_as_MQTT_Broker_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "35.21739130434782%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABNUlEQVQY022Oy07CQBiFp+1c0unAXLgoBjC9CbYU1CaibChBElmWEDW6c+MDKCay8xUEjFvf1ABCTPDLtzhnc/4f4FwZAOAPbgfv38nb5+Xr4mIyb7/Mlk7m7cnsN//x/PmjO/3qTxeg7BwxLqq1uDN+ql/fBcN7tz+2e6NqMqp002oyOuztmKT21U00SMFZ+lgbPmTKLiFY13WwwTB0i5oIQvAfGgAQQqCUQpiYWenFnVIQ5/yW8prKi4r1s2L9VHlN6UbSjbZhZUP5LTNfAoW8hEsMSimGEEFjvQ0NXdO07Sld07XdB1gurwFgZngljAt+xO1grXBC6YRiU7kTSv9EOIGwA2EfS7dBCwfALe5DhBAmTEhiMUwZogyZFuVSsCyhDK4qZsra8wTjxOLQ4pgJYmV+AK4CN0fcezVMAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a054e29a7c5a55f86c9080e2a5f433c3/e4706/ioBroker_as_MQTT_Broker_01.avif 230w", "/en/static/a054e29a7c5a55f86c9080e2a5f433c3/d1af7/ioBroker_as_MQTT_Broker_01.avif 460w", "/en/static/a054e29a7c5a55f86c9080e2a5f433c3/7f308/ioBroker_as_MQTT_Broker_01.avif 920w", "/en/static/a054e29a7c5a55f86c9080e2a5f433c3/e1c99/ioBroker_as_MQTT_Broker_01.avif 1380w", "/en/static/a054e29a7c5a55f86c9080e2a5f433c3/7736f/ioBroker_as_MQTT_Broker_01.avif 1535w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a054e29a7c5a55f86c9080e2a5f433c3/a0b58/ioBroker_as_MQTT_Broker_01.webp 230w", "/en/static/a054e29a7c5a55f86c9080e2a5f433c3/bc10c/ioBroker_as_MQTT_Broker_01.webp 460w", "/en/static/a054e29a7c5a55f86c9080e2a5f433c3/966d8/ioBroker_as_MQTT_Broker_01.webp 920w", "/en/static/a054e29a7c5a55f86c9080e2a5f433c3/445df/ioBroker_as_MQTT_Broker_01.webp 1380w", "/en/static/a054e29a7c5a55f86c9080e2a5f433c3/04f38/ioBroker_as_MQTT_Broker_01.webp 1535w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a054e29a7c5a55f86c9080e2a5f433c3/81c8e/ioBroker_as_MQTT_Broker_01.png 230w", "/en/static/a054e29a7c5a55f86c9080e2a5f433c3/08a84/ioBroker_as_MQTT_Broker_01.png 460w", "/en/static/a054e29a7c5a55f86c9080e2a5f433c3/c0255/ioBroker_as_MQTT_Broker_01.png 920w", "/en/static/a054e29a7c5a55f86c9080e2a5f433c3/b1001/ioBroker_as_MQTT_Broker_01.png 1380w", "/en/static/a054e29a7c5a55f86c9080e2a5f433c3/c5394/ioBroker_as_MQTT_Broker_01.png 1535w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a054e29a7c5a55f86c9080e2a5f433c3/c0255/ioBroker_as_MQTT_Broker_01.png",
              "alt": "INSTAR MQTT for ioBroker",
              "title": "INSTAR MQTT for ioBroker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Make sure that the prefix field is left empty, you check the option to publish topics if their value changed and set the `}<strong parentName="p">{`Quality-of-Service`}</strong>{` to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`1 - At least once`}</code>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b8dbebea10300f7371d60827bfae9039/d0ab7/ioBroker_as_MQTT_Broker_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "60.434782608695656%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABg0lEQVQoz41RS2uTURCd133NfdQv+SSGxjb6UaVSKKhpcWMXUYtFUHBRUlGQ7rtoBenOn6CluO5PlZsoIhr1cGDOhZk7c2bA9W8CwMbTwyefrvY+Xj76cLF7drF79mVnOSenn/fOv07PL2F1vQsxjTcnj2cnmy/fb7063nj+ptuf3do/Wspns+7g3fbBIdx/fXz3xds0XEcAZob/hhBBr22dRmYppVhriZCZiQgRawoiLESViD/04gltey0wD5xPKyspxRC8iOScU4qqQUSsNaohBM/MIhKjqgbVQESQen1GVJGUS87ZWgsAvV7TNE3O2RjjvS+lpJRUFbHOtUDtfHswFGNEJARvrSFCYySlZEztaa313otUKzIPv4wdtFim62I0FyQCAGKOGp1zf/b5uw7GNP3WBQUkpLowmn/0F/wsrksqJafkrGWi77ZEaAlwfpRahogE4J11KZoQjPeO0SCYf95aVQEgD0bdve2d0drD0eqDcTeaTG9MpsPl7I/vtGvdN3odOFynSA/dAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b8dbebea10300f7371d60827bfae9039/e4706/ioBroker_as_MQTT_Broker_02.avif 230w", "/en/static/b8dbebea10300f7371d60827bfae9039/d1af7/ioBroker_as_MQTT_Broker_02.avif 460w", "/en/static/b8dbebea10300f7371d60827bfae9039/7f308/ioBroker_as_MQTT_Broker_02.avif 920w", "/en/static/b8dbebea10300f7371d60827bfae9039/e1c99/ioBroker_as_MQTT_Broker_02.avif 1380w", "/en/static/b8dbebea10300f7371d60827bfae9039/08155/ioBroker_as_MQTT_Broker_02.avif 1532w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b8dbebea10300f7371d60827bfae9039/a0b58/ioBroker_as_MQTT_Broker_02.webp 230w", "/en/static/b8dbebea10300f7371d60827bfae9039/bc10c/ioBroker_as_MQTT_Broker_02.webp 460w", "/en/static/b8dbebea10300f7371d60827bfae9039/966d8/ioBroker_as_MQTT_Broker_02.webp 920w", "/en/static/b8dbebea10300f7371d60827bfae9039/445df/ioBroker_as_MQTT_Broker_02.webp 1380w", "/en/static/b8dbebea10300f7371d60827bfae9039/26719/ioBroker_as_MQTT_Broker_02.webp 1532w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b8dbebea10300f7371d60827bfae9039/81c8e/ioBroker_as_MQTT_Broker_02.png 230w", "/en/static/b8dbebea10300f7371d60827bfae9039/08a84/ioBroker_as_MQTT_Broker_02.png 460w", "/en/static/b8dbebea10300f7371d60827bfae9039/c0255/ioBroker_as_MQTT_Broker_02.png 920w", "/en/static/b8dbebea10300f7371d60827bfae9039/b1001/ioBroker_as_MQTT_Broker_02.png 1380w", "/en/static/b8dbebea10300f7371d60827bfae9039/d0ab7/ioBroker_as_MQTT_Broker_02.png 1532w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b8dbebea10300f7371d60827bfae9039/c0255/ioBroker_as_MQTT_Broker_02.png",
              "alt": "INSTAR MQTT for ioBroker",
              "title": "INSTAR MQTT for ioBroker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You can now use a tool like the `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/Windows_macOS_and_LINUX_Apps/"
      }}>{`Mqtt Explorer`}</a>{` to test the connection. The exclamation mark should disappear once a connection is established:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/49a9e2c028b783eb9ea31d566201d6ba/8733b/ioBroker_as_MQTT_Broker_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "43.04347826086957%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAB0klEQVQozzXOz3OaQBjG8QVrigpGRCSa+BMFNCBio6IVXMDdBRHroWk7k3YaD3Z6aic59Zxjp+f+vR11+p3P9XnnBQCAVnDv/vo7+/F7+vPP8NvLmHwazaPBLNQn2LCxPkXWPBovt/oU307QmT5BxhSDDMOkmQwjCAzHvebYi1zupuXK2qapkEaXyFrYVMhRFze6pN6Jzlrauq3FoPfucGXc3rWq17LICDRfY6WKVK/VZVlut9qKompaT9M0RVE0rWea5mAwME5UVQWscJUtX17L1Ru1kq9m+TonSqWhaUK4nM3s9XqdJMlmE2OMoigihPi+jzEiGPX7fZACoClkR3ApNPiMmC7U2LIkmubQ8zzHcZIk8U95nhfH8Xa7hRBuTh3HNKD4Emu9fVPtlLPiRanJSxXJHJiu657HGOM4jler1fnKKggIxoHvG7oOKIp6lU4bd+NyRcpxOa7AiaJoWZbjOLZ9fNt1XQih4zhohQKC5zjwEAyIr5sOoFN0hmV6I6NQzjP5C7aYFUrFxWKx2+0QQo//OxwOeB3ee+HTNPwI3z+GD/2OAlI0DShwdIqmKb7IW5YFIbRte7/ffz/1/PTsE/RhgV+G0Wfn4evyi1qv/QPUQWgrywCZ7wAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/49a9e2c028b783eb9ea31d566201d6ba/e4706/ioBroker_as_MQTT_Broker_03.avif 230w", "/en/static/49a9e2c028b783eb9ea31d566201d6ba/d1af7/ioBroker_as_MQTT_Broker_03.avif 460w", "/en/static/49a9e2c028b783eb9ea31d566201d6ba/7f308/ioBroker_as_MQTT_Broker_03.avif 920w", "/en/static/49a9e2c028b783eb9ea31d566201d6ba/e1c99/ioBroker_as_MQTT_Broker_03.avif 1380w", "/en/static/49a9e2c028b783eb9ea31d566201d6ba/05b63/ioBroker_as_MQTT_Broker_03.avif 1495w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/49a9e2c028b783eb9ea31d566201d6ba/a0b58/ioBroker_as_MQTT_Broker_03.webp 230w", "/en/static/49a9e2c028b783eb9ea31d566201d6ba/bc10c/ioBroker_as_MQTT_Broker_03.webp 460w", "/en/static/49a9e2c028b783eb9ea31d566201d6ba/966d8/ioBroker_as_MQTT_Broker_03.webp 920w", "/en/static/49a9e2c028b783eb9ea31d566201d6ba/445df/ioBroker_as_MQTT_Broker_03.webp 1380w", "/en/static/49a9e2c028b783eb9ea31d566201d6ba/4a9a8/ioBroker_as_MQTT_Broker_03.webp 1495w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/49a9e2c028b783eb9ea31d566201d6ba/81c8e/ioBroker_as_MQTT_Broker_03.png 230w", "/en/static/49a9e2c028b783eb9ea31d566201d6ba/08a84/ioBroker_as_MQTT_Broker_03.png 460w", "/en/static/49a9e2c028b783eb9ea31d566201d6ba/c0255/ioBroker_as_MQTT_Broker_03.png 920w", "/en/static/49a9e2c028b783eb9ea31d566201d6ba/b1001/ioBroker_as_MQTT_Broker_03.png 1380w", "/en/static/49a9e2c028b783eb9ea31d566201d6ba/8733b/ioBroker_as_MQTT_Broker_03.png 1495w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/49a9e2c028b783eb9ea31d566201d6ba/c0255/ioBroker_as_MQTT_Broker_03.png",
              "alt": "INSTAR MQTT for ioBroker",
              "title": "INSTAR MQTT for ioBroker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`After saving the settings and restarting the MQTT adapter, your camera should push out all retained `}<strong parentName="p">{`MQTT Status Topics`}</strong>{` and ioBroker will add them to the `}<strong parentName="p">{`Object Tree`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b3546fca4a61096c6ce5d46b6b83200d/5148a/ioBroker_as_MQTT_Broker_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAB3ElEQVQoz1WRS2+bQBRGWTiOcDAPy2RevAwBgxkYhhkM2MZpK2UV2Yuqyabq//8bFdiR2qujuzv6dL+reEHgeB5xXUSwDaDfXPzjb7T/wO3nP3xEr3/i409V0zdh2Pc9ABAToiBCsONATABCzxDm3Zt8+6TDNT9d6HD94lL9+MXOV88fJwgCiKBt2wp2HOI4iNzlbJfKpuY1E5LzmpVVUYuKc1awkjK2y7IoisIw9H0fADDKYzJCNzmlBW9a2R1kf2CiYbKR3UF0XdW0TLaMsTRNkyQxDGM+n99lMMk2ALTip2/fu9PAm33BRZxmfrAZG3H9LWWMsTiON5uNqqqz2YOCp5vBV/KuKPeHI2/2ZS1GOM9okeY0zemOUoiQruuGYZimqeu6Qlznv8JK1g/n9nC8yUw0lWyYkHXblbUghAAAlsulpmmqqo5tO56HCYEYA4jyknWnod63N7m459eVkFlRrNdrAAAhZLVazWYzBWI8PXmUnyGkrOqHoeT1FCtfkpgQBAixXdcLo4eHuWVZAABN0+aPj6PsTPL9VUVZty0TckLk8riVr5xWQ5YX6W6haaZlGab59KSpi8WU7LgQ4VtnUbK9NzTtRJzj/r3N+fs2q+JEt1a6Yd4wTOsvHrFehzxgjGIAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b3546fca4a61096c6ce5d46b6b83200d/e4706/ioBroker_as_MQTT_Broker_04.avif 230w", "/en/static/b3546fca4a61096c6ce5d46b6b83200d/d1af7/ioBroker_as_MQTT_Broker_04.avif 460w", "/en/static/b3546fca4a61096c6ce5d46b6b83200d/7f308/ioBroker_as_MQTT_Broker_04.avif 920w", "/en/static/b3546fca4a61096c6ce5d46b6b83200d/9dc19/ioBroker_as_MQTT_Broker_04.avif 1228w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b3546fca4a61096c6ce5d46b6b83200d/a0b58/ioBroker_as_MQTT_Broker_04.webp 230w", "/en/static/b3546fca4a61096c6ce5d46b6b83200d/bc10c/ioBroker_as_MQTT_Broker_04.webp 460w", "/en/static/b3546fca4a61096c6ce5d46b6b83200d/966d8/ioBroker_as_MQTT_Broker_04.webp 920w", "/en/static/b3546fca4a61096c6ce5d46b6b83200d/d565e/ioBroker_as_MQTT_Broker_04.webp 1228w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b3546fca4a61096c6ce5d46b6b83200d/81c8e/ioBroker_as_MQTT_Broker_04.png 230w", "/en/static/b3546fca4a61096c6ce5d46b6b83200d/08a84/ioBroker_as_MQTT_Broker_04.png 460w", "/en/static/b3546fca4a61096c6ce5d46b6b83200d/c0255/ioBroker_as_MQTT_Broker_04.png 920w", "/en/static/b3546fca4a61096c6ce5d46b6b83200d/5148a/ioBroker_as_MQTT_Broker_04.png 1228w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b3546fca4a61096c6ce5d46b6b83200d/c0255/ioBroker_as_MQTT_Broker_04.png",
              "alt": "INSTAR MQTT for ioBroker",
              "title": "INSTAR MQTT for ioBroker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You can now use a tool like Mqtt Explorer to update a `}<strong parentName="p">{`Command Topic`}</strong>{` and see your cameras response in the `}<strong parentName="p">{`Status Topic`}</strong>{` in ioBroker:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0e0dea3a0789c12f05f0c34383d32b3a/b5dee/ioBroker_as_MQTT_Broker_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "64.78260869565217%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACQElEQVQoz1WQ21LaUBSGN2aHZOccAgFEwSaioA1JdkICBDAmdAarVmPFw1AfoLTTXvYJWjt9E6fyjh2SjoPf/BfrYn2zDoCpNqk8RXJC9+tj7/uf/rff3S+PePHL/vzTWjzihx+DyZ13PMOjxAou8PAyix9e+9ENEGVFlGSQy+VpxPICLcispDC8QCIuT8GC2p7eLE9ulye3fydXTxeflh/my7P759P753cfn4AsSTyLCEiWKjWB4whiA0KolEqQIHI5wLLs6dnF0VFsWa7j+LbdXQ9w7xbWzaL5/kFrGQjRAACaovWGDlIoKh8dh7PZdRgeRdFxv+ebZseyzCygfmCoWkuoaY3dliDwK4GAOlfIZJqmgyBIkiQIgvF43Ov1TNN6AYgMpEgCktRO80CWJAAASdJbeieTEaId17+8ehgMBo7jYIytNYCsVlluNXBb2xfEVCZIo4UpigIAMAzT6XTOz8/jODYMw3oNUAqFrI8TZW3/kIQEAECSpHq9nsmu685msziOwzD0fd9c2xs05DKfnkrmKf3ALBaL6zJCqN/vJ0kymUziOE5vXpMhzZIQrmSK1tqdSrWaynKj0Vg9DKHhcDifz6MoCsPQ87xXa78AIbm9o/MMAgAIPF8tq9nDPK87nU5Ho9F4PLZt+9Xk/+Ryypt2ezgt6ofsprZreXtugKpaaWcPOw523DRpgR3bxqsaOy/yxuZbvzU6qdlDpe3i+LTZjytmUNg1xFJFLJVXKZYltVLebqi1enFzS1LUf1yWcyfl8/IsAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0e0dea3a0789c12f05f0c34383d32b3a/e4706/ioBroker_as_MQTT_Broker_05.avif 230w", "/en/static/0e0dea3a0789c12f05f0c34383d32b3a/d1af7/ioBroker_as_MQTT_Broker_05.avif 460w", "/en/static/0e0dea3a0789c12f05f0c34383d32b3a/7f308/ioBroker_as_MQTT_Broker_05.avif 920w", "/en/static/0e0dea3a0789c12f05f0c34383d32b3a/a66a0/ioBroker_as_MQTT_Broker_05.avif 1237w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0e0dea3a0789c12f05f0c34383d32b3a/a0b58/ioBroker_as_MQTT_Broker_05.webp 230w", "/en/static/0e0dea3a0789c12f05f0c34383d32b3a/bc10c/ioBroker_as_MQTT_Broker_05.webp 460w", "/en/static/0e0dea3a0789c12f05f0c34383d32b3a/966d8/ioBroker_as_MQTT_Broker_05.webp 920w", "/en/static/0e0dea3a0789c12f05f0c34383d32b3a/3deba/ioBroker_as_MQTT_Broker_05.webp 1237w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0e0dea3a0789c12f05f0c34383d32b3a/81c8e/ioBroker_as_MQTT_Broker_05.png 230w", "/en/static/0e0dea3a0789c12f05f0c34383d32b3a/08a84/ioBroker_as_MQTT_Broker_05.png 460w", "/en/static/0e0dea3a0789c12f05f0c34383d32b3a/c0255/ioBroker_as_MQTT_Broker_05.png 920w", "/en/static/0e0dea3a0789c12f05f0c34383d32b3a/b5dee/ioBroker_as_MQTT_Broker_05.png 1237w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0e0dea3a0789c12f05f0c34383d32b3a/c0255/ioBroker_as_MQTT_Broker_05.png",
              "alt": "INSTAR MQTT for ioBroker",
              "title": "INSTAR MQTT for ioBroker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Note`}</strong>{`: In Broker mode the `}<strong parentName="p">{`Status Topics`}</strong>{` will also be resolved to their `}<strong parentName="p">{`RAW Value`}</strong>{`. So instead of seeing a value of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`{"val":"1"}`}</code>{` you will have to work with the raw value `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`1`}</code>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/511b5ea9b3be09b836aebd61b358a1da/05fb0/ioBroker_as_MQTT_Broker_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "72.60869565217392%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABO0lEQVQoz5WS207CQBCGZ6e7dGd3tqXQglBOKZJyMoDxeCVGxRfQ938VI22U4M36Za7+ZGb+fzJApDWRspFlDslgECilpJQAIIRARADAIwAgpaIaQ0QQkiFjEVH8ARGllEIIKaVSSgihtUbE4AgAQEPTYnOTNJvgDTN/r60YXy57/Vx4tFXmnXPGmFpK2lnssbnKAgBEFIZhrU7Kq4t+7m/bWqu1rufNN3f5YFhN9eHXNmJQlGvHDODbbIypbWvD0+WGmUEIr6psV9cez1blYqV1wz+zc1zbnr99rg8fvduXbLc/rc718+D+9UzMdvt0+zR6PAwe3uPZFtLhtFjtKE4CYmncaSkbnSnSOCSO0i63MmwQdPLJYr1tJ//6MGuqzK1ufzQt4zgSAr0PxvXBmmk3HxfB8e88+fmwL+VwG028XHnlAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/511b5ea9b3be09b836aebd61b358a1da/e4706/ioBroker_as_MQTT_Broker_06.avif 230w", "/en/static/511b5ea9b3be09b836aebd61b358a1da/d1af7/ioBroker_as_MQTT_Broker_06.avif 460w", "/en/static/511b5ea9b3be09b836aebd61b358a1da/7f308/ioBroker_as_MQTT_Broker_06.avif 920w", "/en/static/511b5ea9b3be09b836aebd61b358a1da/73433/ioBroker_as_MQTT_Broker_06.avif 1138w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/511b5ea9b3be09b836aebd61b358a1da/a0b58/ioBroker_as_MQTT_Broker_06.webp 230w", "/en/static/511b5ea9b3be09b836aebd61b358a1da/bc10c/ioBroker_as_MQTT_Broker_06.webp 460w", "/en/static/511b5ea9b3be09b836aebd61b358a1da/966d8/ioBroker_as_MQTT_Broker_06.webp 920w", "/en/static/511b5ea9b3be09b836aebd61b358a1da/22e6c/ioBroker_as_MQTT_Broker_06.webp 1138w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/511b5ea9b3be09b836aebd61b358a1da/81c8e/ioBroker_as_MQTT_Broker_06.png 230w", "/en/static/511b5ea9b3be09b836aebd61b358a1da/08a84/ioBroker_as_MQTT_Broker_06.png 460w", "/en/static/511b5ea9b3be09b836aebd61b358a1da/c0255/ioBroker_as_MQTT_Broker_06.png 920w", "/en/static/511b5ea9b3be09b836aebd61b358a1da/05fb0/ioBroker_as_MQTT_Broker_06.png 1138w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/511b5ea9b3be09b836aebd61b358a1da/c0255/ioBroker_as_MQTT_Broker_06.png",
              "alt": "INSTAR MQTT for ioBroker",
              "title": "INSTAR MQTT for ioBroker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      